.logo {
  padding: 0 8px;
  position: relative;
  background: none;
  border: none;
  display: flex;
  align-items: center;
}
.logo img {
  display: inline-block;
  width: 70px;
  height: auto;
}

.logo span {
  display: inline-block;
  margin-left: 16px;
  border-left: 1px solid #d0d0d0;
  padding-top: 4px;
  padding-left: 16px;
  padding-bottom: 4px;
  position: relative;
  color: #6d6eed;
}